import Image from 'next/image';
import { MyRichText } from '../../../layout';
import styles from './imageTextGrid.module.scss';

export const ImageTextGrid = ({ section }: any) => {
  const renderImageTextItem = (item: any, index: number) => {
    return (
      <div key={index} className={styles.item}>
        <div className={styles.imgWrapper}>
          <Image
            src={item.image.url}
            layout='fill'
            objectFit='cover'
            alt='Image'
          />
        </div>
        <div className={styles.content}>
          <MyRichText richText={item.richText} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.container} ${
        section.backgroundColor === 'Black'
          ? styles.containerDark
          : section.backgroundColor === 'Sand'
          ? styles.containerSand
          : ''
      }`}
    >
      <div className={styles.widthBox}>
        <div className={styles.title}>
          <MyRichText
            richText={section.titleRichText}
            darkBackground={section.backgroundColor === 'Black'}
          />
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.slider}>
            {section.imageTextItems.map(renderImageTextItem)}
          </div>
        </div>
      </div>
    </div>
  );
};
