import Image from "next/image";
import styles from "././professionSection.module.scss";
import DownArrowIcon from "../../../../public/icons/ico-arrow-down.svg";
import Link from "next/link";

export const ProfessionSection = ({ section }: any) => {
  if (!section) return null;
  return (
    <div className={styles.container}>
      {section.titleTop && (
        <div className={styles.title}>{section.titleTop}</div>
      )}
      <div className={styles.items}>
        {section.items.map((item: any, index: number) => {
          const title = item.title ? item.title : item.page?.name ?? "";
          const image = item.image?.url
            ? item.image?.url
            : item.page?.mobileMedia?.url ?? "";

          const href = item.externalUrl
            ? item.externalUrl
            : item.page?.slug
            ? process.env.NEXT_PUBLIC_BASE_URL + "/" + item.page.slug
            : "";

          if (!title || !href) return null;

          return (
            <Link key={index} href={href}>
              <a
                className={`${styles.linkItem} ${
                  index == 0 ? styles.firstItem : ""
                }`}
                target={item.externalUrl ? "_blank" : "_self"}
                rel="noreferrer"
              >
                <div
                  className={`${styles.item} ${
                    image && image != "" ? "" : styles.noImage
                  }`}
                >
                  {image && image != "" && (
                    <Image
                      src={image}
                      layout="fill"
                      objectFit="cover"
                      alt="Image"
                    />
                  )}
                  <div className={styles.itemTitle}>
                    <DownArrowIcon />
                    {title}
                  </div>
                </div>
              </a>
            </Link>
          );
        })}
      </div>
      {section.titleBottom && (
        <div className={`${styles.title} ${styles.titleBottom}`}>
          {section.titleBottom}
        </div>
      )}
    </div>
  );
};
