import { useState } from "react";
import styles from "./accordionItem.module.scss";
import { MyRichText } from "../myRichText/myRichText";
import PlusIcon from "../../../../public/icons/ico-plus.svg";

type Props = {
  title: string;
  richText: string;
  firstItem?: boolean;
};

export const AccordionItem = ({
  title,
  richText,
  firstItem = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.accordion} ${firstItem ? styles.firstItem : ""}`}>
      <div className={styles.title} onClick={toggle}>
        {title}
        <div className={`${styles.icon} ${isOpen ? styles.open : ""}`}>
          <PlusIcon />
        </div>
      </div>
      {isOpen && <MyRichText richText={richText} />}
    </div>
  );
};
