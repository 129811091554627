import { MyRichText } from "../../layout";
import styles from "./contentSection.module.scss";

export const ContentSection = ({ section }: any) => {
  if (!section) return null;
  return (
    <div className={styles.section}>
      <div
        className={section.fullWidth ? styles.richTextFull : styles.richText}
      >
        <MyRichText
          darkBackground={section.backgroundColor === "Black"}
          richText={section.richText}
        />
      </div>
    </div>
  );
};
