import styles from "./pageBannerSection.module.scss";
import HeartIcon from "../../../../public/icons/ico-heart.svg";
import Image from "next/image";
import { MyLinkItem } from "../../layout/myLinkItem/myLinkItem";
import { BaseLink } from "../../../models/base";
import { ParallaxBanner } from "react-scroll-parallax";

export const PageBannerSection = ({ section }: any) => {
  if (!section) return null;
  const renderBox = (mobile: boolean) => {
    return (
      <div className={styles.wrapper}>
        <div
          className={`${styles.boxContainer} ${
            mobile ? styles.mobileBox : ""
          } ${section.boxPosition == "Right" ? styles.boxToRight : ""}`}
        >
          <div className={styles.box}>
            <div className={styles.title}>{section.title}</div>
            <div className={styles.links}>
              {section?.links?.map((val: BaseLink, index: number) => {
                return (
                  <div key={index}>
                    <MyLinkItem index={index} item={val} />
                  </div>
                );
              })}
            </div>
            <div className={styles.badge}>
              <HeartIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <div className={styles.desktopImage}>
          <ParallaxBanner
            layers={[
              { image: section.desktopMedia?.url ?? "", speed: -20 },
              { children: renderBox(false), speed: 30 },
            ]}
            style={{ height: "100%" }}
          ></ParallaxBanner>
        </div>

        <div className={styles.mobileImage}>
          <Image
            src={section.mobileMedia?.url ?? ""}
            layout="fill"
            objectFit="cover"
            alt="Image"
          />
        </div>
      </div>
      {renderBox(true)}
    </div>
  );
};
