import styles from "./contactGridSection.module.scss";
import Image from "next/image";

export const ContactGridSection = ({ section }: any) => {
  if (!section) return null;
  const getGridItem = (item: any, index: number) => {
    return (
      <div key={index} className={styles.item}>
        <div className={styles.imgWrapper}>
          <Image
            src={item.image.url}
            layout="fill"
            objectFit="cover"
            alt="Image"
          />
        </div>
        <p className={styles.name}>{item.fullName}</p>
        <p
          style={{
            marginBottom: !section.showContactInfo && "0px",
          }}
          className={styles.workTitle}
        >
          {item.workTitle}
        </p>
        {section.showContactInfo && (
          <div>
            {item.phone && (
              <div>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {"Tel: "}
                </span>

                <span
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  <a href={`tel: ${item.phone}`}>{item.phone}</a>
                </span>
              </div>
            )}
            {item.email && (
              <p className={styles.email}>
                <a href={`mailto: ${item.email}`}>{item.email}</a>
              </p>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.widthBox}>
        <p className={styles.title}>{section.title}</p>
        <div className={styles.grid}>{section.contacts.map(getGridItem)}</div>
      </div>
    </div>
  );
};
