import Image from 'next/image';
import { MyRichText } from '../../../layout';
import styles from './imageTextSlider.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import ArrowLeft from '../../../../../public/icons/ico-chevron-left.svg';
import ArrowRight from '../../../../../public/icons/ico-chevron-right.svg';

export const ImageTextSlider = ({ section }: any) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  if (!section) return null;

  const maxIndex = section.imageTextItems?.length;

  const renderImageTextItem = (item: any, index: number) => {
    return (
      <div key={index} className={styles.carouselItem}>
        <div className={styles.imgWrapper}>
          <Image
            src={item.image.url}
            layout='fill'
            objectFit='cover'
            alt='Image'
          />
        </div>
        <div className={styles.content}>
          <MyRichText richText={item.richText} />
        </div>
      </div>
    );
  };

  const goForward = () => {
    if (currentIndex + 1 >= maxIndex) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex(currentIndex + 1);
  };

  const goBackward = () => {
    if (currentIndex <= 0) {
      setCurrentIndex(maxIndex - 1);
      return;
    }
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <div
      className={`${styles.container} ${
        section.backgroundColor === 'Black'
          ? styles.containerDark
          : section.backgroundColor === 'Sand'
          ? styles.containerSand
          : ''
      }`}
    >
      <div className={styles.widthBox}>
        <div className={styles.title}>
          <MyRichText
            richText={section.titleRichText}
            darkBackground={section.backgroundColor === 'Black'}
          />
        </div>
        <div className={styles.sliderWrapper}>
          <div
            onClick={() => goBackward()}
            className={`${styles.btn} ${
              section.backgroundColor === 'Black' ? styles.btnDark : ''
            }`}
          >
            <ArrowLeft />
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              maxWidth: '1360px',
              margin: '0 auto',
            }}
          >
            <Carousel
              showThumbs={false}
              showArrows={false}
              showIndicators={false}
              autoPlay={false}
              onChange={setCurrentIndex}
              selectedItem={currentIndex}
              className={styles.slider}
              swipeable={true}
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={10}
            >
              {section.imageTextItems?.map(renderImageTextItem)}
            </Carousel>
          </div>
          <div
            onClick={() => goForward()}
            className={`${styles.btn} ${
              section.backgroundColor === 'Black' ? styles.btnDark : ''
            }`}
          >
            <ArrowRight />
          </div>
        </div>
        <div
          className={`${styles.carouselIndicators} ${
            section.backgroundColor === 'Black' ? styles.dark : ''
          }`}
        >
          {section.imageTextItems?.map((val: any, index: number) => {
            return (
              <div
                onClick={() => setCurrentIndex(index)}
                key={index}
                className={`${styles.indicator} ${
                  index === currentIndex ? styles.active : null
                }`}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
