import styles from "./uspSection.module.scss";
import HeartIcon from "../../../../public/icons/ico-heart.svg";
import { useState } from "react";
import { InView } from "react-intersection-observer";

export const UspSection = ({ section }: any) => {
  const [activeItem, setActiveItem] = useState(0);

  if (!section) return null;

  return (
    <div className={styles.container}>
      <div className={styles.titleTop}>{section.titleTop}</div>
      <div className={styles.items}>
        {section.uspItems.map((item: any, index: number) => {
          return (
            <div key={index}>
              <InView
                rootMargin="-50px 0px"
                threshold={1}
                onChange={(visible: any) => {
                  if (visible) {
                    setActiveItem(index);
                  }
                }}
              >
                <div
                  className={`${styles.item} ${
                    activeItem == index ? styles.active : ""
                  }`}
                >
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.description}>{item.description}</div>
                  <div className={styles.badge}>
                    <HeartIcon />
                  </div>
                </div>
              </InView>
            </div>
          );
        })}
      </div>
    </div>
  );
};
