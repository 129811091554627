import Head from 'next/head';

type Props = {
  title: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: any;
  hidePage?: boolean;
  excludeLinks?: boolean;
  faqs?: any;
};

export const MyHead = ({
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  hidePage,
  excludeLinks,
  faqs,
}: Props) => {
  const getFaqStructuredData = () => {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqs.map((faq: any) => {
        return {
          '@type': 'Question',
          name: faq.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: faq.richText,
          },
        };
      }),
    };
  };
  return (
    <>
      <Head>
        <title>{`${
          process.env.NEXT_PUBLIC_ENVIRONMENT != 'prod'
            ? `${process.env.NEXT_PUBLIC_ENVIRONMENT.toUpperCase()} - `
            : ''
        }${title}`}</title>

        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />

        <meta name='title' content={title} />
        <meta name='description' content={description} />
        <meta property='og:title' content={ogTitle ?? title} />
        <meta
          property='og:description'
          content={ogDescription ?? description}
        />
        {ogImage && <meta property='og:image' content={ogImage} />}
        <meta
          name='robots'
          content={`${hidePage ? 'noindex' : 'index'}, ${
            excludeLinks ? 'nofollow' : 'follow'
          }`}
        />
        {faqs && (faqs?.length ?? 0) > 0 && (
          <script
            defer
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(getFaqStructuredData()),
            }}
          ></script>
        )}
      </Head>
    </>
  );
};
