import styles from "./sectionWrapper.module.scss";

type BackgroundColor = "White" | "Sand" | "Black";

type Props = {
  backgroundColor?: BackgroundColor;
  children?: React.ReactNode;
};

export const SectionWrapper = ({ backgroundColor, children }: Props) => {
  const backgroundClass =
    backgroundColor == "White"
      ? styles.backgroundWhite
      : backgroundColor == "Sand"
      ? styles.backgroundSand
      : backgroundColor == "Black"
      ? styles.backgroundBlack
      : styles.backgroundWhite;

  return (
    <div className={`${styles.sectionWrapper} ${backgroundClass}`}>
      {children}
    </div>
  );
};
