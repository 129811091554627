import { ImageTextGrid } from "./imageTextGrid/imageTextGrid";
import { ImageTextSlider } from "./imageTextSlider/imageTextSlider";

export const ImageTextSection = ({ section }: any) => {
  if (!section) return null;
  return section.slider ? (
    <ImageTextSlider section={section} />
  ) : (
    <ImageTextGrid section={section} />
  );
};
