import styles from "./ctaContactSection.module.scss";
import Image from "next/image";

export const CtaContactSection = ({ section }: any) => {
  const contact = section.contact[0];

  if (!contact || !section) return null;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{section.title}</div>

      <div className={styles.contactCard}>
        <div className={styles.photo}>
          <Image
            src={contact.image?.url ?? ""}
            layout="fill"
            objectFit="cover"
            alt={contact.fullName}
          />
        </div>
        <div className={styles.information}>
          <div className={styles.name}>{contact.fullName}</div>
          <div className={styles.workTitle}>{contact.workTitle}</div>
          <div className={styles.phone}>
            <span className={styles.tel}>Tel: </span>
            <a href={`tel: ${contact.phone}`}>{contact.phone}</a>
          </div>
          <div className={styles.email}>
            <a href={`mailto: ${contact.email}`}>{contact.email}</a>
          </div>
        </div>
      </div>
    </div>
  );
};
