import Link from "next/link";
import { BaseLink } from "../../../models/base";
import styles from "./myLinkItem.module.scss";
import ArrowRightIcon from "../../../../public/icons/ico-arrow-right.svg";

type Props = {
  item: BaseLink;
  index: number;
};

export const MyLinkItem = ({ item, index }: Props) => {
  if (!item) return null;

  const title = item.title ? item.title : item.page.title;
  const href = item.externalUrl
    ? item.externalUrl
    : item.page?.slug
    ? process.env.NEXT_PUBLIC_BASE_URL + "/" + item.page.slug
    : "";

  if (!title || !href) return null;

  return (
    <Link href={href}>
      <a
        className={`${styles.linkItem} ${index == 0 ? styles.firstItem : ""}`}
        target={item.externalUrl ? "_blank" : "_self"}
        rel="noreferrer"
      >
        {title}
        <div className={styles.icon}>
          <ArrowRightIcon />
        </div>
      </a>
    </Link>
  );
};
