import { ContactGridSection } from './contactGridSection/contactGridSection';
import { ContentSection } from './contentSection/ContentSection';
import { CtaContactSection } from './ctaContactSection/ctaContactSection';
import { FaqSection } from './faqSection/faqSection';
import { ImageTextSection } from './imageTextSection/imageTextSection';
import { PageBannerSection } from './pageBannerSection/pageBannerSection';
import { ProfessionSection } from './professionSection/professionSection';
import { SectionWrapper } from './sectionWrapper/sectionWrapper';
import { UspSection } from './uspSection/uspSection';

type Props = {
  sections: any;
};

const renderSection = (section: any) => {
  switch (section.__typename) {
    case 'ContactGridSection':
      return <ContactGridSection section={section} />;
    case 'ContentSection':
      return <ContentSection section={section} />;
    case 'CtaContactSection':
      return <CtaContactSection section={section} />;
    case 'FaqSection':
      return <FaqSection section={section} />;
    case 'ImageTextListSection':
      return <ImageTextSection section={section} />;
    case 'PageBannerSection':
      return <PageBannerSection section={section} />;
    case 'ProfessionSection':
      return <ProfessionSection section={section} />;
    case 'UspSection':
      return <UspSection section={section} />;

    default:
      return null;
  }
};

export const SectionPicker = ({ sections }: Props) => {
  if (!sections || sections?.length == 0) return null;

  const sectionsWithoutWrapper = ['PageBannerSection', 'ImageTextListSection'];

  return (
    <>
      {sections.map((section: any, index: number) => {
        var backgroundColor = section.backgroundColor;

        if (
          section.__typename === 'CtaContactSection' &&
          backgroundColor == 'Black'
        )
          backgroundColor = 'Sand';

        if (sectionsWithoutWrapper.includes(section.__typename)) {
          return <div key={index}>{renderSection(section)}</div>;
        }

        return (
          <SectionWrapper key={index} backgroundColor={backgroundColor}>
            {renderSection(section)}
          </SectionWrapper>
        );
      })}
    </>
  );
};
