import { AccordionItem } from "../../layout/accordionItem/accordionItem";
import styles from "./faqSection.module.scss";

export const FaqSection = ({ section }: any) => {
  if (!section) return null;
  return (
    <div className={styles.container}>
      <div className={styles.title}>{section.title}</div>
      <div className={styles.faqs}>
        {section.faqItems.map((faq: any, index: number) => {
          return (
            <div key={index}>
              <AccordionItem
                title={faq.title}
                richText={faq.richText}
                firstItem={index == 0}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
