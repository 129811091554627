export const getAllFaqs = (sections: any) => {
  let faqs: any = [];
  sections
    ?.filter((section: any) => section.__typename == "FaqSection")
    .forEach((section: any) => {
      faqs = faqs.concat(section.faqItems);
    });

  return faqs;
};
